.dates-wrapper {
    width: 60%;
    margin: 0 auto;
}

.dates {
    padding: 0.5em;
    margin: 0.2em 0;
    border-radius: 5px;
    border: 2px solid #000;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dates-list-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

button {
    border: none;
	padding: 10px 20px;
	display: inline-block;
	outline: none;
	position: relative;
    color: #fff;
	background: #226fbe;
    cursor: pointer;
}

button.red {
    background-color: red;
}

.mr-2 { margin-right: 2rem; }

.catalog-buttons { margin: 1rem 0 4rem 0; }